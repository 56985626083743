<template>
    <div>
        <b-modal id="ModalTypeOrganizationsEdit" title="Редактирование организации" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="getData">
            <b-row class="mb-1">
                <b-col cols="12">
                    <label for="">Наименование</label>
                    <b-input v-model="organization.name" placeholder="Наименование" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="organization.is_active" class="custom-control-primary">
                    {{ organization.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editBodyType"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,  
    },
    props: ['id'],
    data(){
        return {
            organization: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        clearData() {
            this.organization = []
        },
        getData(){
            this.$http.get(`/orgtype/get_org_type/${this.id.id}`)
            .then(res => {
                this.organization = res.data
                this.organization.is_active = this.organization.is_active ? true : false
            })
        },
        closeModal() {
            this.$bvModal.hide('ModalTypeOrganizationsEdit')
        },
        editBodyType() {
            this.submitButtonDisabled = true
            this.organization.is_active = this.organization.is_active == true ? 1 : 0
            this.$http.patch(`orgtype/get_org_type/${this.id.id}`, this.organization)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип организации изменён!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
    },
}
</script>