<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить организацию" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row class="mb-1">
                <b-col cols="12">
                    <b-input v-model="name" placeholder="Название организация" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="is_active" class="custom-control-primary">
                    {{ is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createBodyType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
    },
    data(){
        return {
            name: null,
            is_active: true,
            submitButtonDisabled: false,
            categoriesSelectDisabled: false,
        }
    },
    methods: {
        clearData(){
            this.name = null,
            this.is_active = true
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        createBodyType() {
            this.submitButtonDisabled = true
            const data = {
                name : this.name
            }
            this.$http.post('/orgtype/add_orgtype', data)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип организации создан!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        
    }
}
</script>