<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="options"
            :fields="fields"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            head-row-variant="secondary"
            >
            <template #cell(checkbox)="row">
              <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>    
            <template #cell(is_active)="data">
                    <span v-if="data.item.is_active == 1">
                        Активен
                    </span>
                    <span v-else>
                        Не активен
                    </span>
            </template>
            <template #cell(property)="data">
                <span>
                    {{ JSON.parse(data.item.property).join(', ') }}
                </span>
            </template>
            <template #cell(created_at)="data">
                <span>
                {{data.item.created_at.substring(0, data.item.created_at.indexOf('T')) + ' ' + data.item.created_at.substring(data.item.created_at.indexOf('T') + 1, data.item.created_at.indexOf('.'))}}
                </span>
            </template>
            <template #cell(updated_at)="data">
                <span>
                {{data.item.updated_at.substring(0, data.item.updated_at.indexOf('T')) + ' ' + data.item.updated_at.substring(data.item.updated_at.indexOf('T') + 1, data.item.updated_at.indexOf('.'))}}
                </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ['options'],
  data() {
    return {
        fields: [
          { key: 'checkbox', label: 'Флаг', thStyle: { width: "30px" },},
          { key: 'id', label: 'ID', sortable: true, thStyle: { width: "80px" },},
          { key: 'name', label: 'Имя', sortable: true, thStyle: { width: "150px" },},
          { key: 'is_active', label: 'Статус', sortable: true, thStyle: { width: "150px" },},
          { key: 'created_at', label: 'Создан', sortable: true, thStyle: { width: "150px" },},
          { key: 'updated_at', label: 'Обработан', sortable: true, thStyle: { width: "150px" },},
        ]
      }
    },
    mounted() {
      resizeable()
    },
    methods: {
      editForm(id) {
        this.$emit('editForm', id)
      },
      clicked(item, index, event){
        this.$store.state.filterPlus.tableIndex = index
        const clickedElement = event.target;
              const clickedCell = clickedElement.closest('td');
  
              if (!clickedCell) {
                  return;
              }
  
              const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
  
              if (columnIndex < 0 || columnIndex >= this.fields.length) {
                  return;
              }
  
              const clickedField = this.fields[columnIndex];
              this.$store.state.filterPlus.keyFilter = clickedField.key;
              this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
              cellSelect()
          },
    }
  }
  </script>
  <style scoped lang="scss">
  @import '@core/scss/vue/libs/vue-context.scss';
  .my-card .card-body{
      padding: 0px;
  }
  .my-card{
  overflow: auto; 
  height: calc(100vh - 92px);
  }
  @media (max-height: 700px) {
    .my-card{
      overflow: auto; 
      height: 530px;
      }
  }
  table{
      outline: none; 
      table-layout: fixed; 
      -webkit-user-select: none;
      overflow: hidden;
      position: relative;
  }
  tr, td, th{
      padding: 0;
  }
  td, th{ 
      width: 100px; 
      white-space: nowrap; 
      overflow: hidden;  
      text-align: left; 
      box-sizing: border-box; 
      min-width: 60px;
       }    
  </style>
  