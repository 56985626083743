<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
     <table-type-organization :options="organization" @editForm="editForm"></table-type-organization>
    </div>
    <modal-type-organization @refresh="refresh" ></modal-type-organization>
    <modal-type-organization-edit :id="id" @refresh="refresh"></modal-type-organization-edit>
  </div>
</template>
<script>
import ModalTypeOrganization from '@/views/component/Modal/ModalTypeOrganization/ModalTypeOrganization.vue'
import ModalTypeOrganizationEdit from '@/views/component/Modal/ModalTypeOrganization/ModalTypeOrganizationEdit.vue'
import tableTypeOrganization from '@/views/component/Table/tableTypeOrganization.vue'
export default {
  components: {
    ModalTypeOrganization,
    ModalTypeOrganizationEdit,
    tableTypeOrganization,
  },
  data() {
    return {
      organization: [],
      showPreloader: false,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Название' },
        { key: 'is_active', label: 'Статус' },
      ],
      selectedBodyType: null,
      id: undefined,
    }
  },
  mounted(){
    this.$store.commit('pageData/setdataCount', null)
    this.refresh()
  },
  methods: {
    editForm(id) {
        this.id = id
        setTimeout(() => {this.$bvModal.show('ModalTypeOrganizationsEdit')}, 0)
    },
    refresh(){
      this.showPreloader = true
      this.$http
      .get('orgtype')
      .then(res => {
        this.organization = res.data
        this.$store.commit('pageData/setdataCount', this.organization.length)
        this.showPreloader = false
        this.$store.commit('REFRESH_DATA', false)
      }).catch(err => {})
    },
  },
  computed: {
    fetchingNewData(){
      return this.$store.state.refresh.fetchingNewData
    }
  },
  watch: {
    fetchingNewData(val){
      if (val){
        let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
        this.$http
        .get(`${this.$route.name}`, {params: params})
        .then(res =>{
          this.organization = res.data
          this.$store.commit('pageData/setdataCount', this.organization.length)
          this.$store.commit('REFRESH_DATA', false)
        })
      }
    }
  }
}
</script>